import React from 'react';
import PropTypes from 'prop-types';
import 'prismjs/themes/prism-okaidia.css';

import asyncComponent from '../AsyncComponent';
import Headline from '../Article/Headline';
import Bodytext from '../Article/Bodytext';
import Meta from './Meta';
import Author from './Author';
import Comments from './Comments';
import NextPrev from './NextPrev';
import SuscriptionForm from '../Suscription';

const Share = asyncComponent(() =>
	import('./Share')
		.then(module => {
			return module.default;
		})
		.catch(error => { })
);

const Post = props => {
	const {
		post,
		post: { html, fields: { prefix, slug }, frontmatter: { title, author, category } },
		authornote,
		facebook,
		next: nextPost,
		prev: prevPost,
		theme,
		t,
		i18n
	} = props;

	return (
		<React.Fragment>
			<header>
				<Headline title={title} theme={theme} />
				<Author note={authornote} theme={theme} />
				<Meta t={t} i18n={i18n} prefix={prefix} author={author} category={category} theme={theme} />
			</header>
			<Bodytext html={html} theme={theme} />
			<footer>
				<SuscriptionForm t={t} i18n={i18n} theme={theme} />
				<Share t={t} i18n={i18n} post={post} theme={theme} />
				<NextPrev next={nextPost} prev={prevPost} theme={theme} />
				{facebook.appId && facebook.appId !== '' && <Comments slug={slug} facebook={facebook} theme={theme} />}
			</footer>

			{/* --- STYLES --- */}
			<style jsx global>{`
				blockquote {
					background: #f9f9f9;
					border-left: 5px solid #ccc;
					margin: 1.5em 10px;
					padding: 0.8em 10px;
				}
				blockquote:before {
					color: #ccc;
					content: open-quote;
					font-size: 3em;
					line-height: 0.1em;
					margin-right: 0.25em;
					vertical-align: -0.4em;
				}
				blockquote p {
					display: inline;
					color: #514f4d;
				}
			`}</style>
		</React.Fragment>
	);
};

Post.propTypes = {
	post: PropTypes.object.isRequired,
	authornote: PropTypes.string.isRequired,
	facebook: PropTypes.object.isRequired,
	next: PropTypes.object,
	prev: PropTypes.object,
	theme: PropTypes.object.isRequired,
	t: PropTypes.func.isRequire,
	i18n: PropTypes.object.isRequired
};

export default Post;
