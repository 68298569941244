import PropTypes from 'prop-types';
import React from 'react';
import { graphql, Link } from 'gatsby';

import { Redirect } from '@reach/router'


require('prismjs/themes/prism-okaidia.css');

import Seo from '../components/Seo';
import Article from '../components/Article';
import Post from '../components/Post';
import { ThemeContext } from '../layouts';
import { withTrans } from '../i18n/withTrans';
import { getCurrentLang } from '../utils/helpers';


const PostTemplate = props => {
	const {
		data: { post, site: { siteMetadata: { facebook } } },
		pageContext: { next, prev },
		t,
		i18n,
		language
	} = props;

	const currentLang = getCurrentLang(i18n.language);
	const authornote = props.data[`authornote${currentLang}`].html
	const contentPost = post.frontmatter;
	if (contentPost.ref && post.fields.slug != contentPost.ref && contentPost.lang && currentLang != contentPost.lang) {
		if (typeof window !== 'undefined') {
			window.location = `/${contentPost.ref}/`;
			return null;
		}
	}

	return (
		<React.Fragment>
			<ThemeContext.Consumer>
				{theme => (
					<Article theme={theme}>
						<Post
							t={t}
							i18n={i18n}
							post={post}
							next={next}
							prev={prev}
							authornote={authornote}
							facebook={facebook}
							theme={theme}
						/>
					</Article>
				)}
			</ThemeContext.Consumer>

			<Seo data={post} facebook={facebook} />
		</React.Fragment>
	);
};

PostTemplate.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired
};

export default withTrans(PostTemplate);

//eslint-disable-next-line no-undef
export const postQuery = graphql`
	query PostBySlug($slug: String!) {
		post: markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			html
			excerpt
			fields {
				slug
				prefix
			}
			frontmatter {
				ref
				lang
				title
				author
				category
				cover {
					childImageSharp {
						resize(width: 1200) {
							src
						}
					}
				}
			}
		}
		authornotees: markdownRemark(fileAbsolutePath: { regex: "/author-es/" }) {
			id
			html
		}
		authornoteen: markdownRemark(fileAbsolutePath: { regex: "/author-en/" }) {
			id
			html
		}
		site {
			siteMetadata {
				facebook {
					appId
				}
			}
		}
	}
`;
