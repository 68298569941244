import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import config from '../../../content/meta/config';
import avatar from '../../images/jpg/avatar.jpg';

import FacebookIcon from '!svg-react-loader!../../images/svg-icons/i8-facebook.svg?name=FacebookLogo';
import InstagramIcon from '!svg-react-loader!../../images/svg-icons/i8-instagram.svg?name=InstagramLogo';
import TwitterIcon from '!svg-react-loader!../../images/svg-icons/i8-twitter.svg?name=TwitterLogo';

const Author = props => {
	const { note, theme } = props;

	return (
		<React.Fragment>
			<div className="author">
				<Link to={`/sobremi`}>
					<div className="avatar">
						<img
							src={config.gravatarImgMd5 == '' ? avatar : config.gravatarImgMd5}
							alt={config.siteTitle}
						/>
					</div>
				</Link>

				<div className="note" dangerouslySetInnerHTML={{ __html: note }} />
			</div>
			<div className="social-icons">
				<a rel="noopener noreferrer" href={`${config.authorSocialLinks.twitter}`} target="_blank">
					<TwitterIcon width={30} height={30} />
				</a>
				<a rel="noopener noreferrer" href={`${config.authorSocialLinks.facebook}`} target="_blank">
					<FacebookIcon width={30} height={30} />
				</a>
				<a rel="noopener noreferrer" href={`${config.authorSocialLinks.instagram}`} target="_blank">
					<InstagramIcon width={30} height={30} />
				</a>
			</div>

			{/* --- STYLES --- */}
			<style jsx>{`
				.author {
					margin: 0em 0em 0.5em;
					padding: 0.8em 0em 0.1em;
					border-top: 1px solid ${theme.line.color};
				}
				.avatar {
					float: left;
					border-radius: 50% 50%;
					border: 1px solid ${theme.line.color};
					display: inline-block;
					height: 55px;
					margin: 5px 20px 0 0;
					overflow: hidden;
					width: 55px;
				}
				.avatar img {
					width: 100%;
				}

				.social-icons {
					border-bottom: 1px solid ${theme.line.color};
					margin-left: -5px;
					padding-bottom: 0.3em;
					margin-bottom: em;
					:global(a) {
						margin-left: 5px;
					}
				}
				.note {
					font-size: 0.74em;
					line-height: 1.6;
					color: ${theme.color.neutral.gray.j};
				}
				@from-width tablet {
					.note {
						font-size: 1em;
					}
					.author {
						display: flex;
					}
					.avatar {
						flex: 0 0 auto;
					}
				}
			`}</style>
		</React.Fragment>
	);
};

Author.propTypes = {
	note: PropTypes.string.isRequired,
	theme: PropTypes.object.isRequired
};

export default Author;
